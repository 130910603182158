import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { Link } from 'gatsby';
import { TimelineLite } from 'gsap';
import waitForGlobal from 'utils/waitForGlobal';

import s from './About.scss';

interface IProps {
  children: React.ReactNode;
}

export default class About extends PureComponent<IProps> {

  refCanvas = React.createRef<HTMLDivElement>();
  refWrapper = React.createRef<HTMLDivElement>();
  refScene = React.createRef<HTMLDivElement>();
  refLogo = React.createRef<HTMLHeadingElement>();

  componentDidMount() {
    waitForGlobal('Emblem').then(() => this.setupRotate());
  }

  setupRotate = () => {
    Emblem.init('#zultans');
    const t = new TimelineLite();

    t.addLabel('start');

    if (this.refLogo.current) {
      t.fromTo(
        this.refLogo.current,
        0.8,
        { autoAlpha: 0, y: '20px' },
        { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
        'start',
      );
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className={s.about}>
        <Link to="/" className={s.about__logo} ref={this.refLogo} id="zultans">ZóldánarSveiflunnar</Link>

        <div className={s.about__content}>
          <div className={s.about__inner}>
            <div className={s.about__circle} />
            <h2 className={s.about__heading}>Not Today</h2>
            <h2 className={s.about__subheading}>Maybe Tomorrow</h2>
          </div>
        </div>

      </div>
    );
  }
}
