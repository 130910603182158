import * as React from 'react';
import Helmet from 'react-helmet';
import About from 'components/about/About';
import { withPrefix, graphql, Link } from 'gatsby';

export default () => (
  <>
    <Helmet title="About">
      <script src={withPrefix('about.js')} type="text/javascript" />
    </Helmet>

    <About />
  </>
);
